import { createSlice } from "@reduxjs/toolkit";

export const usdcSlice = createSlice({
    name : 'usdc',
    initialState : {
        amount : null
    },
    reducers : {
        setAmount : (state, data) => {
            state.amount = data.payload;
        }
    }
});

export const {
    setAmount
} = usdcSlice.actions;

export default usdcSlice.reducer;