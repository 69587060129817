import { createSlice } from "@reduxjs/toolkit";

export const utilitySlice = createSlice({
  name: "utility",
  initialState: {
    refresh: false,
    resetSearchBar: false,
    goBackSourcePage: null,
    goBackData: null,
  },
  reducers: {
    setRefresh: (state, data) => {
      console.log("refresh", { data });
      state.euroValue = data.payload;
    },
    setResetSearchBar: (state, data) => {
      console.log("store reset", data);
      state.resetSearchBar = data.payload;
    },
    setGoBack: (state, data) => {
      console.log("store goback", data);
      state.goBackSourcePage = data.payload.goBackSourcePage;
      state.goBackData = data.payload.goBackData;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRefresh, setResetSearchBar, setGoBack } = utilitySlice.actions;

export default utilitySlice.reducer;
