import { useHistory } from "react-router"
import styles from "./SignInModal.module.sass"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { magic } from "../../../controller/utils";
import { setMagicUser } from "../../../redux/counterSlice";
import { CircularProgress } from "@material-ui/core";
const SignInModal = ({ handleConnectWallet, setLoginModal }) => {
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const loginTypes = [
        {
            text: "Login With Google",
            provider: "google",
            image: "/images/google.svg"
        },
        // {
        //     text: "Login With Facebook",
        //     provider: "facebook",
        //     image: "/images/facebook 1.svg"
        // },
        // {
        //     text: "Login With Discord",
        //     provider: "discord",
        //     image: "/images/discord 1.svg"
        // },
        {
            text: "Login With Twitter",
            provider: "twitter",
            image: "/images/xicon.png"
        },
        {
            text: "Login With Microsoft",
            provider: "microsoft",
            image: "/images/microsoft.png"
        },
        // {
        //     text: "Login With Linkedin",
        //     provider: "linkedin",
        //     image: "/images/linkedin.svg"
        // },
        // {
        //     text: "Login With Apple",
        //     provider: "apple",
        //     image: "/images/appleIcon.png"
        // },

    ]
    const handleLogin = async (type) => {
        if (type == "email") {
            history.push("/magiclogin");
        }
        else {
            setLoginModal(false)
            await handleConnectWallet()

        }
    }

    const magicUser = useSelector((state) => state.counter.magicUser);
    const dispatch = useDispatch()
    useEffect(() => {
        if (!magicUser) {
            magic.user.logout().then((res) => {
                dispatch(setMagicUser(null))
            }
            )
        }
    }, [])
    const handleSocialMediaLogin = async (type) => {
        setLoader(true)
        const didToken = await magic.oauth.loginWithRedirect({
            provider: type,
            redirectURI: `${window.location.origin}/`

        });
    }

    return (
        <div className={styles.modal}>
            <div style = {{display:'flex', height: '80vh', overflow:'none'}}>
            <div className={styles.modal_content}>
                {loader &&
                    <div className={styles.loaderPad}>
                        <div className={styles.loader}>
                            <CircularProgress color="black" />
                        </div>
                    </div>

                }
                <div className={styles.close} onClick={() => {
                    setLoginModal(false)
                }}>
                    <img src="/images/close-line.svg" />
                </div>

                <div className={styles.info}>
                    <img src="/images/info.svg" />
                    <h3>Connect your wallet/login using email</h3>
                </div>
                <div className={styles.signInOptions}>
                    <div className={styles.option} onClick={() => handleLogin("wallet")}>
                        <p>Register or login with Wallet</p>
                        <img src="/images/walletIcon.svg" />
                    </div>
                    <div className={styles.option} onClick={() => handleLogin("email")}>
                        <p>Register or login with E-mail</p>
                        <img src="/images/emailIcon.svg" />
                    </div>
                </div>
                <div className={styles.separator}>
                    <p className={styles.or}>Or login with</p>
                </div>
                <div className={styles.socialLogin}>
                    {
                        loginTypes.map((item, index) => (
                            <div className={styles.logo} onClick={() => {
                                handleSocialMediaLogin(item.provider)
                            }}>
                                <img src={item.image} loading="lazy" />
                            </div>
                        ))
                    }

                </div>
            </div>
            </div>
            
        </div>
    );
}

export default SignInModal;