import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Image from "../Image";
import User from "./User";
import Web3Modal from "web3modal";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
  Room,
  ExitToApp,
  ExitToAppOutlined,
  PersonOutlineOutlined,
  PersonOutlineRounded,
  PersonOutlineSharp,
  // AssignmentOutlinedIcon,
  FavoriteBorderOutlined,
} from "@material-ui/icons";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import OutsideClickHandler from "react-outside-click-handler";
import Paper from "@material-ui/core/Paper";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  details,
  increment,
  setMagicUser,
  setBalance,
  setAccountUserName,
} from "../../redux/counterSlice";
import { setBasicAccountDetails } from "../../redux/accountSlice";
import {
  addNetworkTometamask,
  connector,
  getBalanceToken,
  getWeb3Provider,
  maticWeb3,
  PopUpAlert,
} from "./../../controller/utils";
import { setCirlceDetails } from "../../redux/circlePaymentSlice";
import { setRefresh } from "../../redux/utilitySlice";
import axios from "./../../utils/API";
import Catagories from "./Catagories";
import Loader from "../../screens/Item/Loader";
import Swal from "sweetalert2";
import { magic } from "./../../controller/utils";
import { axiosInstance } from "../../utils/API";
import { withRouter } from "react-router";
import Icon from "../../components/Icon";
import useWindowSize from "../../common/hooks/useWindowSize";
import MobileHeader from "./MobileHeader";
import IdleTimer from "./IdleTimer";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ListItemText, withStyles, makeStyles } from "@material-ui/core";
import Dropdown from "../Dropdown";
import SignInModal from "./SignInModal/SignInModal";
import { setProfileDetails } from "../../redux/profileSlice";
import { setAmount } from "../../redux/usdcSlice";
import { getUsdcBalanceFromAccount } from "../../utils/balance";

const nav = [
  {
    url: "/search01/all?tab=explore",
    title: "Explore",
    auth: false,
  },
  // {
  //   url: "/dashboard",
  //   title: "Profile",
  //   auth: true,
  // },
  {
    url: "/search01/all?tab=collections",
    title: "Collections",
    auth: false,
  },
  {
    url: "/news",
    title: "News",
    auth: false,
  },
  // {
  //   url: "/item",
  //   title: "Purchased Asset",
  //   auth: true,
  // },
];

const StyledMenu = withStyles({
  paper: {
    border: "0.063rem solid #d3d4d5",
    borderRadius: "0.313rem",
  },
  list: {
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "black",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const Headers = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const { sourcePage = null } = props;
  const [loading, setLoading] = useState(false);
  const [metaMaskActive, setMetaMaskActive] = useState(false);
  const [magicLinkLoader, setMagicLinkLoader] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [screenWidth] = useWindowSize();
  const [tab,setTab] = useState()
  let loginWith = localStorage.getItem("network");
  const isLogin = useSelector((state) => state.counter.value);
  const userFullName = useSelector((state) => state.counter.accountUserName);
  let walletId = useSelector((state) => state.circlePayment.walletId);
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  let refresh = useSelector((state) => state.utilityReducer.refresh);
  const resetSearchBar = useSelector(
    (state) => state?.utilityReducer?.resetSearchBar
  );
  const accountDetails = useSelector((state) => state.accountReducer);
  const isCreator = accountDetails?.isCreatedByAdmin;
  window.ethereum?.on("chainChanged", async (chainId) => {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    const acc = accounts[0];
    let web3 = new Web3(Web3.givenProvider);
    let bal = await web3.eth.getBalance(acc);
    bal = web3.utils.fromWei(bal, "ether");
    dispatch(setBalance({ bal }));

    let usdcBalance = await getUsdcBalanceFromAccount(acc);
    dispatch(setAmount(usdcBalance));

    window.location.reload();
  });
  const handleMenuClick = (event) => {
    console.log("event.currentTarget", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const Call = async () => {
      console.log("connector ");
      let web3 = await getWeb3Provider();
      // const accounts = await window.ethereum.request({
      //   method: "eth_accounts",
      // });
      // if (loginWith !== "metamask") {
      // 	web3 = new Web3(maticWeb3);
      // } else {
      // 	web3 = new Web3(Web3.givenProvider);
      // }
      const account = await web3?.eth?.getAccounts();
      // const acc = account[0];

      let bal = await web3?.eth?.getBalance(account[0]);
      let balance = web3?.utils?.fromWei(bal, "ether");
      console.log("given balance", balance);
      dispatch(setBalance({ balance }));

      let usdcBalance = await getUsdcBalanceFromAccount(account[0]);
      dispatch(setAmount(usdcBalance));
    };
    try {
      Call();
    } catch (error) {
      console.log("Error while fetching acocunt balance", error);
    }
    if (window?.ethereum) {
      listenAccountChange();
    }
  }, []);

  const userAccount = useSelector((state) => state?.counter?.user);

  const listenAccountChange = () => {
    if (window?.ethereum) {
      //logout user forcefully if the metamsk account is switched.
      window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        if (accounts[0] && userAccount?.account[0]) {
          if (accounts[0] !== userAccount?.account[0]) {
            handleDisconnect(false);

          }

        }
      })
    }
  }
  //Checking For Search Reset from MarketPlace

  useEffect(() => {
    setSearch("");
    // resetSearchBar
  }, [resetSearchBar]);

  // Checking Magic Link balance

  useEffect(() => {
    const checkBalance = async () => {
      if (magicLinkUser?.publicAddress) {
        let magicBalance = await fetchBalance(magicLinkUser?.publicAddress);
        let userMetadata = {
          ...magicLinkUser,
          magicBalance: magicBalance,
        };
        dispatch(setMagicUser(userMetadata));
      }
    };
    checkBalance();
  }, []);

  useEffect(() => {
    if (history.location?.state?.searchData) {
      searchAsset(history.location?.state?.searchData);
      document.getElementById("searchBox")?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location?.state?.searchData, search]);

  //Circle Wallet

  useEffect(() => {
    const getAccountDetails = async () => {
      let circleAccount = await getAccountStatus();
      // if (!circleAccount?.walletId && circleAccount?.balance == null) {
      //   await createCircleWallet();
      // }
      // await getCircleAccount();
    };

    const checkLoggedIn = () => {
      const token = localStorage.getItem("userToken");
      console.log({ token });
      if (!token) {
        // history.push("/Marketplace");
        PopUpAlert("info", "Please login and proceed to wallet.", "error").then(
          (res) => {
            document.getElementById("connectWallet").click();

            return false;
          }
        );
      }
      return true;
    };
    // const isLoggedIn = !checkLoggedIn();
    let isLoggedIn = localStorage.getItem("userToken");
    if (isLoggedIn) getAccountDetails();

    // return () => {

    // }
  }, []);

  const leftTrim = (string) => {
    return string.replace(/^\s+/, "");
  };

  const getAccountStatus = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    let circleAccount = {
      balance: 0,
      walletId: null,
    };
    const result = await axiosInstance
      .get("/user/wallets/balance", config)
      .then((result) => {
        // getETHPrice();
        // setRefreshPage(!refreshPage);
        dispatch(setCirlceDetails(result.data?.result));
        dispatch(setRefresh(!refresh));
        circleAccount.walletId = result.data?.result?.walletId;
        circleAccount.balance = result.data?.result?.balance
          ? result?.data?.result?.balance
          : 0;
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          PopUpAlert("Alert", err.response?.data?.message);
          circleAccount.walletId = null;
          circleAccount.balance = null;
        } else if (err?.response?.data?.message == "No USD balance found") {
          circleAccount.walletId = null;
          circleAccount.balance = 0;
        }
      });
    console.log(circleAccount);
    return circleAccount;
  };

  const createCircleWallet = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };

    const result = await axiosInstance
      .post("/user/wallets", {}, config)
      .then((result) => {
        console.log("Result createCirclewallet", result);
        dispatch(setCirlceDetails(result.data?.result));
        // setRefreshPage(!refreshPage);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    const Call = async () => {
      const stateCredential = new URLSearchParams(props.location.search).get(
        "state"
      );
      const provider = new URLSearchParams(props.location.search).get(
        "provider"
      );
      if (stateCredential && provider) {
        console.log(magicLinkUser);
        console.log(stateCredential);
        // console.log("Entered magic processing")
        setMagicLinkLoader(true);
        await completeSocialMediaLogin(provider);
        // const provider = new URLSearchParams(props.location.search).get(
        //   "provider"
        // );
        // if (!provider) await finishEmailRedirectLogin();
        // history.replace("/");
      }
    };
    Call();
  });
  const completeSocialMediaLogin = async (provider) => {
    const result = await magic.oauth.getRedirectResult();
    console.log("redirect back result", result);
    let userMetadata = result?.magic?.userMetadata;
    const { givenName, familyName } = result?.oauth?.userInfo;
    let magicBalance = await fetchBalance(userMetadata?.publicAddress);
    userMetadata.magicBalance = magicBalance;
    userMetadata.firstName = givenName;
    userMetadata.lastName = familyName;
    userMetadata.socialType = provider;
    dispatch(setMagicUser(userMetadata));
    handleConnectWallet(null, true, userMetadata, provider);
  };

  // magic
  useEffect(() => {
    const Call = async () => {
      const magicCredential = new URLSearchParams(props.location.search).get(
        "magic_credential"
      );
      if (magicCredential && magicLinkUser === null) {
        console.log("Entered magic processing");
        await setMagicLinkLoader(true);
        const provider = new URLSearchParams(props.location.search).get(
          "provider"
        );
        if (!provider) await finishEmailRedirectLogin();
        history.replace("/");
      }
    };
    Call();
  }, []);

  const fetchBalance = (address) => {
    let web3 = new Web3(maticWeb3);
    return web3.eth.getBalance(address).then((bal) => web3.utils.fromWei(bal));
  };

  const finishEmailRedirectLogin = async () => {
    const userMetadata = await magic.user.getMetadata();
    let magicBalance = await fetchBalance(userMetadata?.publicAddress);
    userMetadata.magicBalance = magicBalance;
    dispatch(setMagicUser(userMetadata));
    handleConnectWallet(null, true, userMetadata, "email");
  };

  // redux
  const status = useSelector((state) => state.counter.value);
  const magicUser = useSelector((state) => state.counter.magicUser);
  const dispatch = useDispatch();

  // console.log("status-----------------------", status);
  // web3 -eth
  const providerOptions = useMemo(
    () => ({
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "573fcc11c2d349418d454ca719d655a1", // required
          rpc: {
            80001: "https://rpc-mumbai.maticvigil.com",
          },
        },
      },
    }),
    []
  );

  const web3Modal = useMemo(
    () =>
      new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required
      }),
    [providerOptions]
  );

  if (!connector.connected) {
    connector.createSession();
  }

  connector.on("connect", (error, payload) => {
    if (error) {
      throw error;
    }

    // Get provided accounts and chainId
    const { accounts, chainId } = payload.params[0];
    console.log("Wallet connect on connect", { payload });
    localStorage.setItem("walletconnector", { accounts, chainId });
  });

  connector.on("disconnect", (error, payload) => {
    console.log("Wallet connect on connect", { payload });
    handleDisconnect(false);
    if (error) {
      console.log(error);
      connector = null;
      throw error;
    }
  });

  const handleTabClosing = () => {
    dispatch(decrement());
    if (magicUser) {
      magic.user.logout().then(() => { });
      dispatch(setMagicUser(null));
    }
  };

  useEffect(() => {
    if (status) {
      getProfileDetails();
    }
    // const getTokenBal = setInterval(() => getBalanceToken(), 60000);
    // const logoutUser = setInterval(() => {
    // 	dispatch(decrement());
    // 	window.location.reload();
    // }, 60000 * 25);
    // window.addEventListener("unload", handleTabClosing);
    // return () => {
    // 	clearInterval(getTokenBal);
    // 	clearInterval(logoutUser);
    // 	window.removeEventListener("unload", handleTabClosing);
    // };
    if (localStorage.getItem("_expiredTime") != null && !status) {
      handleDisconnect(true);
    }
    if (status && localStorage.getItem("userToken")) {
      new IdleTimer({
        timeout: 36000, //expire seconds
        onTimeout: () => {
          handleDisconnect(false);
        },
        onExpired: () => {
          handleDisconnect(false);
        },
      });
    }
  }, []);

  const getConfig = () => {
    if (!localStorage.getItem("userToken")) {
      PopUpAlert("Alert", "No wallet connection found", "error");
      return;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    return config;
  };

  const handleDisconnect = async (silentLogout = false) => {
    let payload = {
      walletId: null,
      userId: null,
      balance: null,
      circleAddress: "",
    };

    let accountPayload = {
      email: null,
      firstName: null,
      lastName: null,
      profilePicture: null,
      status: null,
    };
    handleMenuClose();
    // dispatch(setBasicAccountDetails(payload));
    await web3Modal.clearCachedProvider();
    console.log(connector.connected);
    if (connector?.connected) {
      connector?.killSession();
    }
    dispatch(decrement());
    if (magicUser) {
      magic.user.logout().then(() => { });
      dispatch(setMagicUser(null));
    }
    dispatch(setCirlceDetails(payload));
    localStorage.removeItem("userToken");
    localStorage.removeItem("cs_details");
    localStorage.removeItem("network");
    localStorage.removeItem("tokenbalance");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("_expiredTime");
    !silentLogout &&
      PopUpAlert("Log Out", "You have been logged out!", "success").then(() =>
        // history.push("/")
        window.location.href = '/'
      );
  };

  const handleClientLogin = () => {
    setLoginModal(true);
  };

  const getProfileDetails = async () => {
    const config = getConfig();
    const profileDetails = await axiosInstance
      .get(`user/get-profile`, config)
      .then((res) => {
        const {
          id,
          profilePicUrl,
          email,
          firstName,
          lastName,
          userName,
          status,
          loginWith,
          kycStatus,
          isKYCLimitExceeded,
          isCreatedByAdmin,
          emailVerified,
        } = res.data?.result;
        dispatch(setAccountUserName(`${firstName} ${lastName}`));
        setProfilePic(profilePicUrl);
        let payload = {
          email: email,
          firstName: firstName,
          lastName: lastName,
          profilePicture: profilePicUrl,
          userName: userName,
          status: status,
          loginType: loginWith,
          id: id,
          kycStatus: kycStatus,
          isKYCLimitExceeded: isKYCLimitExceeded,
          emailVerified: emailVerified,
          isCreatedByAdmin: isCreatedByAdmin,
        };
        dispatch(setBasicAccountDetails(payload));
        dispatch(setProfileDetails(res.data?.result))
        return payload;
      })
      .catch(() => {
        return false;
      });

    return profileDetails;
  };

  // const fetchBalance = (address) => {
  //       web3.eth
  //         .getBalance(address)
  //         .then((bal) => setBalance(web3.utils.fromWei(bal)));
  //     };

  const searchAsset = async (text) => {
    setSearch(text);
    history.replace({
      pathname: "/search01/all",
      state: {
        searchData: text,
      },
    });
  };
  console.log({ magicLinkUser });

  // Connect Wallet
  const handleConnectWallet = async (
    e,
    magiclink = false,
    magicData = null,
    loginWith = "wallet"
  ) => {
    let redirected = false;
    let web3;
    try {
      if (magiclink) {
        web3 = new Web3(maticWeb3);
      } else {
        if (typeof window.web3 !== "undefined") {
          //console.log("web3 is enabled");
          await web3Modal.clearCachedProvider();
          let acc = await window.ethereum.request({
            method: "eth_accounts",
          });

          web3 = new Web3(maticWeb3);
        } else {
          await web3Modal.clearCachedProvider();
          if (window?.innerWidth <= 768) {

            return Swal.fire({
              html: "Metamask not found on your browser. Navigate to metamask supported enviroment",
              icon: "info",
              confirmButtonText: "Ok",
              showCancelButton: true,
              confirmButtonColor: "#000000",
              cancelButtonColor: "#B8B8B8",
              customClass: {
                confirmButton: "border: none",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const currentUrl = window.location.href;

                const url = new URL(currentUrl);
                redirected = true;
                window.open(`https://metamask.app.link/dapp/${url.hostname}/`, "_blank");
              }
              // else if (result.dismiss === Swal.DismissReason.cancel) {
              // 	redirected = true;
              // }
            });
          }
          await Swal.fire({
            html: "Metamask not found, you can install it by clicking below or cancel to proceed with walletconnect",
            icon: "info",
            confirmButtonText: "Install",
            showCancelButton: true,
            confirmButtonColor: "#000000",
            cancelButtonColor: "#B8B8B8",
            customClass: {
              confirmButton: "border: none",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              redirected = true;
              window.open("https://metamask.io/download", "_blank");
            }
            // else if (result.dismiss === Swal.DismissReason.cancel) {
            // 	redirected = true;
            // }
          });
        }
        if (redirected) return;
        if (addNetworkTometamask(web3)) {
          if (connector?.connected) {
            connector?.killSession();
          }

          let provider;
          try {
            provider = await web3Modal.connect();
            if (history.location.pathname == "/") {
              document.body.style.overflow = "hidden";
            }
            setLoading(true);
          } catch (err) {
            // PopUpAlert('Alert', err, 'info');
            await web3Modal.clearCachedProvider();
            if (history.location.pathname == "/") {
              document.body.style.overflow = "hidden";
            }
            return;
          }

          web3 = new Web3(provider);
          console.log("web3", web3);
        } else {
          return false;
        }
        //console.log("provide", provider);
      }

      const account = await web3.eth.getAccounts();
      let balance = await web3.eth.getBalance(account[0]);
      balance = web3.utils.fromWei(balance, "ether");

      let usdcBalance = await getUsdcBalanceFromAccount(account[0]);

      if (account) {
        try {
          let signature = await web3.eth.personal.sign(
            "Welcome to lii",
            account[0],
            ""
          );

          let payload = {
            signature,
            publicAddress: account[0],
            loginWith,
          };

          if (magicData) {
            payload.email = magicData?.email;
            payload.firstName = magicData?.givenName;
            payload.lastName = magicData?.familyName;
            payload.profilePicUrl = magicData?.profilePicUrl;
          }

          axios
            .post(`/user/authenticate`, payload)
            .then(async (res) => {
              let userdata = res.data?.result;
              localStorage.setItem(
                "cs_details",
                JSON.stringify({
                  account,
                  balance,
                  userId: userdata?.userId,
                })
              );
              localStorage.setItem("userToken", userdata?.accessToken);

              localStorage.setItem(
                "network",
                `${magiclink ? "magic" : "metamask"}`
              );
              dispatch(increment());
              if (!magiclink) dispatch(setMagicUser(null));
              dispatch(details({ account, balance }));

              dispatch(setAmount(usdcBalance));

              getBalanceToken();
              const getProfile = await getProfileDetails();
              if (
                !getProfile?.firstName ||
                !getProfile?.lastName ||
                !getProfile?.email ||
                !getProfile?.userName
              ) {
                PopUpAlert(
                  "Connection Successful",
                  "Please complete your profile.",
                  "success"
                ).then(() =>
                  history.push({
                    pathname: "/my-account",
                    state: { magicRedirect: true },
                  })
                );
              } else {
                PopUpAlert(
                  "Connection Successful",
                  "You are now connected to lii.",
                  "success"
                )
              }
              setMagicLinkLoader(false);
            })
            .catch((err) =>
              PopUpAlert("Alert", err.response?.data?.message, "error").then((result) => {
                window.location.reload()
              })
            );
        } catch (err) {
          if (err.code === 4001) {
            web3Modal.clearCachedProvider();
            PopUpAlert("Alert", "User denied the signature request", "error");
          } else {
            alert("error", err);
          }
          if (history.location.pathname == "/") {
            document.body.style.overflow = "hidden";
          }
          setMagicLinkLoader(false);
        }
      }
    } finally {
      setLoading(false);
      setMagicLinkLoader(false);
    }
  };

  const toggleDrawerDisiplay = (e) => {
    setVisibleNav(!visibleNav);
  };

  const handleLogin = (nav) => {
    if (nav?.auth && !status) {
      document.getElementById("connectWallet").click();
      return;
    }
    setSearch('')
    if (nav.title === "Explore" || nav.title == 'Collection') {
      setTab(nav.title)
      return window.location.href = nav?.url;
    }
    setTab(nav.title)

    history.push(nav?.url);
  };

  const closeDrawer = () => {
    setVisibleNav(false);
  };

  const [pageFullyLoaded, setPageFullyLoaded] = useState(false)

  window.onload = () => {
    setPageFullyLoaded(true)
  }


  const getLikedButton = () => {
    return (
      <div
        className={!sourcePage && styles.love}
        onClick={() => {
          if (isLogin) {
            history.push("/wishlist");
          } else {
            PopUpAlert("Alert", "Login to view your wishlist.", "error").then(
              () => document.getElementById("connectWallet").click()
            );
          }
        }}
      >
        {sourcePage ? (
          <div
            style={{
              marginRight: "1.313rem",
              border: "0.125rem solid white",
              borderRadius: "50%",
              padding: "0.375rem",
              cursor: "pointer",
            }}
          >
            <FavoriteIcon style={{ color: "#fff" }} />
          </div>
        ) : (
          <div className={styles.loveIcon}>
            <img src="/images/Wishlist.svg" alt="love"></img>
          </div>
        )}
      </div>
    );
  };

  const [profiledropAnchor, SetProfiledropAnchor] = useState(false);

  const myPopup = () => {
    const containerPop = {
      position: "absolute",
      //   padding: "0.625rem",
      width: "20.313rem",
      height: isCreator ? "31.25rem" : "24.75rem",
      minHeight: "fit-content",
      top: "2.188rem",
      left: "-4.000rem",
      borderRadius: "0.313rem",
      background: "#FFFFFF",
      paddingTop: "1.188rem",
      zIndex: 2,
    };
    const wrapper = {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 0.313rem 0.938rem",
    };
    const profileDiv = {
      display: "flex",
      width: "100%",
      padding: "0.875rem 0.313rem ",
      borderBottom: "solid",
      borderBottomColor: "#f3f3f3",
      borderBottomWidth: "0.063rem",
      cursor: "pointer",
    };
    const nameDiv = {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      padding: "0.875rem 0.313rem",
      cursor: "pointer",
      borderRadius: "0.313rem 0.313rem 0 0",
      fontWeight: 600,
    };
    const whishListDiv = { ...profileDiv };
    const profileDivHove = { ...profileDiv, background: "red" };
    const signoutDiv = {
      display: "flex",
      width: "100%",
      // justifyContent: "space-between",
      padding: "0.875rem 0.313rem",
      cursor: "pointer",
      // marginLeft: "118px",
    };
    const stylefontColor = {
      color: "#565151",
      marginRight: "1.875rem",
      // fontSize: "0.875rem",
      fontWeight: 500,
    };
    const nameFontColor = {
      maxWidth: 170,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontWeight: 600,
      color: "black",
      fontSize: "1.25rem",
    };
    const poplistContainer = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    };
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          SetProfiledropAnchor(false);
        }}
      >
        <div style={wrapper}>
          <div style={containerPop}>
            <div style={poplistContainer}>
              <Avatar
                style={{ width: 80, height: 80, marginBottom: 5 }}
                alt="Avatar"
                src={
                  status
                    ? profilePic || "/images/content/profile-placeholder.png"
                    : "/images/content/profile-placeholder.png"
                }
              />
              <div
                className="profile"
                style={true ? nameDiv : profileDivHove}
                onClick={() => {
                  history.push("/dashboard");
                  SetProfiledropAnchor(false);
                }}
              >
                <div style={{ marginLeft: "0px" }}></div>
                <div style={nameFontColor}>{userFullName}</div>
              </div>
              <button
                className="blackBtn"
                style={{ marginBottom: 15 }}
                onClick={() => {
                  history.push("/dashboard");
                  SetProfiledropAnchor(false);
                }}
              >
                <PersonOutlineOutlined style={{ fontSize: "0.938rem" }} /> Go to
                Profile
              </button>
              <div
                className="wishlist drop-down-menu"
                style={{ ...whishListDiv, borderTop: "0.063rem solid #f3f3f3" }}
                onClick={() => {
                  history.push("/wishlist");
                  SetProfiledropAnchor(false);
                }}
              >
                <div style={{ marginLeft: "3.625rem" }}>
                  <FavoriteBorderOutlined
                    style={{ ...stylefontColor, fontSize: "0.875rem" }}
                  />
                </div>
                <div style={{ ...stylefontColor, fontSize: "0.813rem" }}>
                  Wishlist
                </div>
              </div>
              {isCreator && (
                <div
                  className="wishlist drop-down-menu"
                  style={whishListDiv}
                  onClick={() => {
                    history.push("/analytics");
                    SetProfiledropAnchor(false);
                  }}
                >
                  <div style={{ marginLeft: "3.625rem" }}>
                    {/* <img src="/images/Wishlist.svg" alt="love" width={"1.875rem"}/> */}
                    <AnalyticsOutlinedIcon
                      style={{ ...stylefontColor, fontSize: "0.875rem" }}
                    />
                  </div>
                  <div style={{ ...stylefontColor, fontSize: "0.813rem" }}>
                    Analytics
                  </div>
                </div>
              )}
              <div
                className="wishlist drop-down-menu"
                style={whishListDiv}
                onClick={() => {
                  history.push("/earnings");
                  SetProfiledropAnchor(false);
                }}
              >
                <div style={{ marginLeft: "3.625rem" }}>
                  {/* <img src="/images/Wishlist.svg" alt="love" width={"1.875rem"}/> */}
                  <MonetizationOnIcon
                    style={{ ...stylefontColor, fontSize: "0.875rem" }}
                  />
                </div>
                <div style={{ ...stylefontColor, fontSize: "0.813rem" }}>
                  Earnings
                </div>
              </div>
              {/* <div
                className="wishlist drop-down-menu"
                style={whishListDiv}
                onClick={() => {
                  history.push("/TermsAndConditions");
                  SetProfiledropAnchor(false);
                }}
              >
                <div style={{ marginLeft: "3.625rem" }}> */}
              {/* <img src="/images/Wishlist.svg" alt="love" width={"1.875rem"}/> */}
              {/* <AssignmentOutlinedIcon
                    style={{ ...stylefontColor, fontSize: "0.875rem" }}
                  />
                </div>
                <div style={{ ...stylefontColor, fontSize: "0.813rem" }}>
                  Terms and Conditions
                </div>
              </div> */}
              <div
                className="sign Out drop-down-menu"
                style={signoutDiv}
                onClick={() => handleDisconnect(false)}
              >
                <div style={{ marginLeft: '3.625rem' }}>
                  <ExitToAppOutlined
                    style={{ ...stylefontColor, fontSize: "0.875rem" }}
                  />
                </div>
                <div style={{ ...stylefontColor, fontSize: "0.813rem" }}>
                  Sign Out{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  };

  const handlingDropdown = () => {
    SetProfiledropAnchor(true);
  };
  const getWalletConnectButton = () => {
    return status ? (
      <div onClick={status ? handlingDropdown : handleClientLogin}>
        {/* <button
		id="connectWallet"
		className={cn("button-stroke button-small", styles.logInButton)}
		style={{
		  boxShadow: sourcePage
			? "0 0 0 0.125rem #fff inset"
			: "0 0 0 0.125rem #000 inset",
		}}
		onClick={status ? handlingDropdown : handleClientLogin}
		disabled={loading}
	  ></button> */}
        <User
          className={styles.user}
          signedIn={status}
          sourcePage={sourcePage}
        />
      </div>
    ) : (
      <>

        <button
          id="connectWallet"
          className={cn("button-stroke button-small", styles.logInButton)}
          style={{
            boxShadow: sourcePage
              ? "0 0 0 0.125rem #fff inset"
              : "0 0 0 0.125rem #000 inset",
          }}
          onClick={status ? handlingDropdown : handleClientLogin}
          disabled={loading}
        // {...loginButtonProps}
        >
          <User
            className={styles.user}
            signedIn={status}
            sourcePage={sourcePage}
          />

          {loading ? (
            <span
              style={{ fontSize: '0.625rem', paddingLeft: "0.4em", width: 105 }}
              className={`${sourcePage ? styles.colorWhite : ""}`}
            >
              Confirm from wallet
            </span>
          ) : status ? (
            <>
              <p
                style={{
                  margin: 0,
                  maxWidth: 100,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: 105,
                }}
                className={`${sourcePage ? styles.colorWhite : ""}`}
              >
                {userFullName}
              </p>
              <>
                {/* <span style={{ paddingLeft: "0.4em" }}
					className={`${sourcePage ? styles.colorWhite : ""}`}
					>  */}

                {/* <div style={{}} onClick={()=>{SetProfiledropAnchor(true)}}>
						</div> */}
                {/* </span> */}

                {profiledropAnchor ? (
                  <ArrowDropUp
                    id="dropArrowHeaderUp"
                    style={{
                      fill: sourcePage ? "white" : "black",
                      marginLeft: 0,
                    }}
                  />
                ) : (
                  <ArrowDropDown
                    id="dropArrowHeaderDown"
                    style={{ fill: sourcePage ? "white" : "black" }}
                  />
                )}
              </>
            </>
          ) : (
            <>

              <span
                style={{}}
                className={`${sourcePage ? styles.colorWhite : ""}`}
              >
                {" "}
                Sign In / Register
              </span>

            </>
          )}
        </button>

        <StyledMenu
          id="connectWallet"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <StyledMenuItem
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            <ListItemText primary="Profile" />
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              history.push("/wishlist");
            }}
          >
            <ListItemText primary="Wishlist" />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleDisconnect(false)}>
            <ListItemText primary="Sign Out" />
          </StyledMenuItem>
        </StyledMenu>
      </>
    );
  };

  return (
    <header
      className={styles.header}
      style={{
        background: sourcePage ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 1)",
      }}
    >

      {loginModal && (
        <SignInModal
          handleConnectWallet={handleConnectWallet}
          setLoginModal={setLoginModal}
        />
      )}
      <div className={cn("container", styles.container)}>
        {/* <a
          className={styles.logo}
          href="/"
          style={{ padding: "1.125rem 0px", opacity: "0" }}
        >
          <Image
            className={styles.pic}
            src={
              sourcePage // screenWidth > 766
                ? "/images/ASLY_White.png"
                : "/images/ASLY_LOGO.png"
            }
            srcDark="/images/aslyLogo.svg"
            alt="logo.jpg"
            style={{
              width: 140,
              position: "relative",
              bottom: "0.6em",
              opacity: "0",
            }}
          />
        </a> */}
        <Link to='/'><img src="/images/liiLogoArabic.png" className="logo" style={{ height: '3rem', width: '10rem', objectFit: 'contain' }}></img></Link>
        {screenWidth > 766 ? (
          <>
            <div
              className={cn(styles.wrapper, {
                [styles.active]: visibleNav,
              })}
            >
              <nav className={styles.nav}>
                {nav.map((x, index) => {
                  const path = history.location.pathname;
                  const urlParam = history.location.search;
                  let activeClass =
                    path.includes("search") &&
                      x.title === "Explore" &&
                      (tab !== 'Collections' && (urlParam.includes("tab") ? urlParam.includes("explore") : true))
                      ? true
                      : (tab === 'Collections' || urlParam.includes('collections')) &&
                        x.title === "Collections"
                        ? true
                        : path === "/dashboard" && x.title === "Profile"
                          ? true
                          : path === "/news" && x.title === "News";
                  return (
                    <button
                      onClick={(e) => handleLogin(x)}
                      className={
                        `${sourcePage ? styles.homeHover : styles.link}` +
                        " " +
                        `${activeClass
                          ? sourcePage
                            ? styles.activeWhite
                            : styles.active
                          : ""
                        }` +
                        " " +
                        `${sourcePage ? styles.colorWhite : ""}`
                      }
                      style={{ textAlign: "left" }}
                      // to={(e) => checkAuthenticatedRoute(e, x)}
                      key={index}
                    >
                      {x.title}
                    </button>
                  );
                })}
                {/* <a className={styles.link} href="/goodcauses" rel="noreferrer"
              style={{ color: "#FF76DC" }}>
              Our Good Causes
            </a> */}
                {/* <Link
              className={styles.link}
              to="/news"
              onClick={() => {
                setVisibleNav(false);
              }}
            >
              In the News
            </Link> */}
              </nav>
              {/* <form
            className={styles.search}
            action=""
            // onSubmit={() => handleSubmit()}
          > */}
              <div
                className={styles.search}
              // onSubmit={() => handleSubmit()}
              >
                <button className={styles.result}>
                  <Icon name="search" size="20" />
                </button>
                <input
                  className={styles.input}
                  type="text"
                  style={{
                    background: sourcePage
                      ? "rgba(0, 0, 0, 0)"
                      : "rgba(255, 255, 255, 1)",
                    border: sourcePage ? "0.125rem solid white" : "0.125rem solid black",
                  }}
                  id="searchBox"
                  value={search}
                  onChange={(e) => searchAsset(leftTrim(e.target.value))}
                  name="search"
                  placeholder="Search by name, creator and collections."
                  required
                />
              </div>
              {/* </form> */}
              {/* <Link
            className={cn("button-small", styles.button)}
            to="/upload-variants"
          >
            Upload
          </Link> */}
            </div>

            {/* <Notification className={styles.notification} />
        <Link
          className={cn("button-small", styles.button)}
          to="/upload-variants"
        >
          Upload
        </Link> */}
            {profiledropAnchor && (
              <>
                <div className={styles.headerEndProfile}>{myPopup()}</div>
                <div className="backdrop"></div>
              </>
            )}
            <div className={styles.headerEndButton}>
              {/* {getLikedButton()} */}
              {getWalletConnectButton()}
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "none" }}>{getWalletConnectButton()}</div>
            <MobileHeader
              searchValue={search}
              searchName="search"
              sourcePage={sourcePage}
              onSearchChange={(e) => searchAsset(e.target.value)}
              signedIn={status}
              nav={nav}
              handleLogin={(item) => handleLogin(item)}
              handleClientLogin={handleClientLogin}
              handleDisconnect={() => handleDisconnect(false)}
            />
          </>
        )}

        {/* <button
          className={cn(styles.burger123, { [styles.active]: visibleNav })}
          onClick={(e) => {
            toggleDrawerDisiplay(e, {});
          }}
        ></button> */}
      </div>
      {metaMaskActive && (
        <div className={styles.metaMask}>Download Metamask</div>
      )}
      {magicLinkLoader && (
        <Loader
          active={magicLinkLoader}
          customize={true}
          title={"Logging in to lii"}
          content={"This will take a few seconds"}
          backGround={"/images/liiLogoArabic.png"}
        />
      )}
    </header>
  );
};

export default withRouter(Headers);
