import React, { useEffect } from "react";
import styles from "./Item.module.sass";
import { CircularProgress } from "@material-ui/core";

const Loader = (props) => {
	const {
		active,
		customize = false,
		title = null,
		content = null,
		type = null,
		backGround = null,
	} = props;


	return (
		<div
			className={styles.checkout + " " + styles.LoaderPopup}
			style={{ display: active ? "flex" : "none" }}
		>
			<div
				className={styles.checkoutInner}
				style={
					backGround
						? {
								height: "55%",
								// background: `url(${backGround}) center center`,
								backgroundSize: "cover",
								alignItems:"center"
						  }
						: { height: "55%" }
				}
				onClick={(e) => e.stopPropagation()}
			>
				<h2>{customize ? title : "Transaction in progress"}</h2>
				<div style={ { textAlign: "center" }}>
					{backGround ? (
						<img
						src={backGround}
						alt="loader"
						// style={{height : "13.125rem"}}
						width={window.screen.width < 560 ?100 : 160}
					/>
					) : (
						<img
							src={"/loader/loader.gif"}
							alt="loader"
							height={"110px"}
						/>
					)}
				</div>
				{/* <div style={"display: flex; align-items:center; justify-content: center"}>
					<img src={"/images/Logo_onWhite.png"} alt="asly"  height={window.screen.height < 700 ? "6.25rem" : "13.125rem"}/>
				</div> */}
				<div className={styles.checkoutDetail}>
					<div>
						{type !== "no" && (
							<p style={{ textAlign: "center" }}>
								{customize ? content : "Item purchase in progress"}
							</p>
						)}
						<p style={{ textAlign: "center" }}>
							Please do not refresh the page
						</p>
					</div>
				</div>
				{/* <div style={{ textAlign: "center", padding: 20 }}>
                    <CircularProgress />
                    <div>
                        <span> Please do not refresh the page.</span>
                    </div>
                </div> */}
			</div>
		</div>
	);
};

export default Loader;
